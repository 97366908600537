import React, { useState, useContext } from "react"
import Seo from "../components/seo"
import { AuthContext } from "../context/auth"
import firebase from "gatsby-plugin-firebase"
import { Link, navigate } from "gatsby"
import { authService } from "../services/auth.service"
import { StaticImage } from "gatsby-plugin-image"
// import { authService } from "../services/auth.service"
import cellopic from "../images/brand/cello_1000x2100.jpeg"

const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
    error: null,
  })

  const { setUser } = useContext(AuthContext)

  const [verified, setVerified] = useState(true)
  const [emailSent, setEmailSent] = useState(false)

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  // [END auth_email_link_actioncode_settings]

  var actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: "localhost",
    // This must be true.
    handleCodeInApp: true,
  }

  const resendEmail = async () => {
    await firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(() => {
        setEmailSent(true)
      })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    setData({ ...data, error: null })

    try {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)

      //check verifiction status:
      const verifyStatus = await result.user.emailVerified

      if (verifyStatus) {
        await setUser(result) //set user in context
        await authService.setLocalUser(result.user.uid) //get user from firestore and store user info in local storage
        navigate("/app/account")
      } else {
        setVerified(false)
      }
    } catch (err) {
      setData({ ...data, error: err.message })
    }
  }

  return (
    <main>
      <div
        className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
        aria-hidden="true"
      >
        <img
          className="object-cover object-center w-full h-full"
          src={cellopic}
          alt=""
          width="760"
          height="1024"
        />
      </div>
      <Seo title="Login" />
      <div className="relative sm:flex">
        <div className="sm:w-1/2 bg-white">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                <Link to="/">
                  <div className="">
                    <StaticImage
                      src="../images/siyo-logo.png"
                      width={80}
                      quality={100}
                      alt="Si-Yo Music Logo"
                    />
                    <span> </span>
                    <StaticImage
                      src="../images/ficec-logo-dark.png"
                      width={90}
                      quality={100}
                      alt="FICEC Logo"
                    />
                  </div>
                </Link>
                <br />
                Welcome Back
              </h1>

              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      className="form-input w-full border p-1 rounded"
                      type="text"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      className="form-input w-full border p-1 rounded"
                      autoComplete="on"
                      type="password"
                      name="password"
                      value={data.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/*display if the email verification is false here*/}

                {!verified ? (
                  <div className="mt-6">
                    <div className="text-red-600">
                      Please verify your email address first.
                    </div>

                    {!emailSent ? (
                      <span className="text-blue-600">
                        Or resend the
                        <a
                          className="text-blue-600 underline"
                          onClick={resendEmail}
                        >
                          &nbsp;Verification Email
                        </a>
                      </span>
                    ) : (
                      <span className="text-blue-600">
                        Email Sent! Please check your inbox.
                      </span>
                    )}
                  </div>
                ) : null}

                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link
                      className="text-sm underline hover:no-underline"
                      to="/reset-password"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <input
                    type="submit"
                    value="Log In"
                    className="bg-indigo-500 border hover:bg-indigo-600 text-white ml-3  py-1 px-4 rounded"
                  />
                </div>
              </form>

              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account?{" "}
                  <Link
                    className="font-medium text-indigo-500 hover:text-indigo-600"
                    to="/register"
                  >
                    Register
                  </Link>
                </div>

                {/* warning message */}
                {data.error ? (
                  <div className="mt-5">
                    <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                      <svg
                        className="inline w-3 h-3 flex-shrink-0 fill-current mr-2"
                        viewBox="0 0 12 12"
                      >
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                      <span className="text-sm">{data.error}</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-full h-full" src="https://picsum.photos/200/300" width="760" height="1024" alt="Authentication" />
          <div className="bg-hero-pattern object-cover object-center w-full h-full"/>
        </div>  */}
      </div>
    </main>
  )
}
export default Login
