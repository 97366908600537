import { navigate } from "gatsby-link"
import firebase from "gatsby-plugin-firebase"

export const authService = {
  getUserById,
  logout,
  setLocalUser,
  isLoggedIn,
  getUser,
}

const isBrowser = () => typeof window !== "undefined"

function getUser() {
  if (isBrowser() && window.localStorage.getItem("localUser")) {
    return JSON.parse(window.localStorage.getItem("localUser"))
  }

  return {}
}

function setUser(user) {
  window.localStorage.setItem("localUser", JSON.stringify(user))
}

function isLoggedIn() {
  const user = getUser()
  return !!user.name
}

async function setLocalUser(uid) {
  let user = await getUserById(uid)
  setUser(user)
}

async function getUserById(uid) {
  if (uid) {
    const data = await (
      await firebase.firestore().collection("users").doc(uid).get()
    ).data()
    return data
  } else {
    console.error("No User Found")
  }
}

function logout() {
  setUser({}) //clean user in local storage
  navigate("/")
  firebase.auth().signOut()
}
